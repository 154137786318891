

































.login-view {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  background-color: #ffffff;
  max-height: 90vh;
}
.emailLink {
  color: #1c9edb;
  font-weight: 700;
  text-decoration: underline;
}
@media (max-height: 700px) {
  .exp-view {
    display: none;
  }
}
